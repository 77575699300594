<style scoped>
.joborder-prev {
  display: inline-block;
  height: 10px;
  width: 20px;
  background: gray;
}
.joborder-next {
  display: inline-block;
  height: 10px;
  width: 20px;
  background: #4299e1;
}
.joborder-current {
  display: inline-block;
  height: 10px;
  width: 20px;
  border: 1.5px solid #fa8b59;
}
</style>

<template>
  <div>
    <Divider dashed><span class="divider-text">调整作业时间</span></Divider>
    <Calendar
      ref="calendar"
      is-expanded
      is-dark
      :attributes="attributes"
      @dayclick="handleDayClick"
      @update:from-page="handleFromMonth"
    />

    <Row class="p-t-10 text-center text-8">
      <i-col span="8"> <label class="joborder-prev" /> 已过作业令 </i-col>
      <i-col span="8"> <label class="joborder-next" /> 新建作业令 </i-col>
      <i-col span="8"> <label class="joborder-current" /> 当前选中 </i-col>
    </Row>
    <div class="text-right m-t-10" >
      <Button type="success" size="small" @click="handleSubmit">确认调整</Button>
    </div>
  </div>
</template>

<script>
import { Calendar } from 'v-calendar'
import { lastMonthDate, ParseDate } from '@/utils/dateFormat'
// import { getWorkdayList } from '@/api/msp/workday'
import { setActionDate } from '@/api/msp/taskitem'

export default {
  components: {
    Calendar
  },
  data () {
    return {
      assetList: [], // 所有可用线路
      allStationList: [], // 所有可用站点

      attributes: [],
      chooseDate: ParseDate(new Date()),
      workdayQuery: {
        startDate: '',
        endDate: ''
      },
      prevDates: [],
      nextDates: [],
      workdays: [] // 工作令集合
    }
  },
  methods: {
    handleFromMonth (param) {
      // 初始化页面后，会自动加载该事件
      this.workdayQuery.startDate =
        param.year + '-' + (param.month + '').padStart(2, '0') + '-01'
      this.workdayQuery.endDate = lastMonthDate(param.year, param.month)
      // getWorkdayList(this.workdayQuery).then((res) => {
      //   this.workdays = res
      //   this.initCalendar()
      // })
    },
    initCalendar () {
      this.prevDates = []
      this.nextDates = []
      const currentDate = new Date()

      this.workdays.forEach((element) => {
        const workday = new Date(element.day)
        if (workday > currentDate || element.day === ParseDate(currentDate)) {
          this.nextDates.push(workday)
        } else {
          this.prevDates.push(workday)
        }
      })

      this.handleDayClick({
        id: ParseDate(this.chooseDate),
        date: this.chooseDate,
        isToday: true
      })
    },
    handleDayClick (day) {
      this.initCalendarDates(day)
    },
    initCalendarDates (day) {
      this.chooseDate = day.id
      this.attributes = [
        {
          key: 'today',
          highlight: { color: 'orange' },
          dates: day.date
        },
        {
          bar: { color: 'gray' },
          dates: this.prevDates
        },
        {
          bar: true,
          dates: this.nextDates
        }
      ]
    },
    handleSubmit () {
      const that = this
      if (that.selectedTaskItemIds.length) {
        const params = {
          taskitemIds: JSON.stringify(that.selectedTaskItemIds),
          actionDate: ParseDate(that.chooseDate)
        }
        setActionDate(params).then(res => {
          if (res && !res.errcode) {
            that.$store.commit('set_board_selectedTaskItemIds', [])
            that.$store.commit('set_board_beginUpdate', new Date())
            that.$Notice.success({ desc: '操作成功' })
          }
        })
      }
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.board.beginUpdate
    },
    selectedTaskItemIds () {
      return this.$store.state.board.selectedTaskItemIds
    }
  }
}
</script>
